import React, { useState } from "react"
import { toast } from "react-toastify"
import style from "./CommentForm.module.scss"

const STATE_NONE = 0
const STATE_LOADING = 1
const STATE_OK = 2
const STATE_ERROR = 3

const FormThankYou = () => (
  <strong>
    Thanks for your comment! It will show on the site once it has been approved.
  </strong>
)
const FormError = () => (
  <strong>
    Sorry, there was an error with your submission. Please make sure all
    required fields have been completed and try again.
  </strong>
)

const staticManDomain = "fragrant-paper-9890.fly.dev"
const staticManUrl = `https://${staticManDomain}/v3/entry/gitlab/Eadhcaillin/blog/master/comments`

const CommentForm = ({ url, slug }) => {
  const [status, setStatus] = useState(STATE_NONE)
  const honeyPotValue = Math.random() + ""
  //const page_url = url + "blog/" + slug

  const onSubmit = evt => {
    evt.preventDefault()
    const form = evt.target

    if (form.age.value !== honeyPotValue) {
      toast(`this hidden field shouldn't be filled. Something is wrong`)
      return
    }
    let has_error = false
    const formData = new FormData()
    ;["name", "email", "message"].forEach(k => {
      const value = form[k].value
      if (!value) {
        has_error = true
        toast(`${k} cannot be blank`)
      }
      formData.append(`fields[${k}]`, form[k].value)
    })
    if (has_error) {
      return
    }
    formData.append("fields[slug]", slug)
    formData.append("options[slug]", slug)
    //formData.append("options[redirect]", page_url)
    const json = {}
    formData.forEach((value, prop) => (json[prop] = value))
    const formBody = Object.keys(json)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(json[key]))
      .join("&")
    setStatus(STATE_LOADING)
    fetch(staticManUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formBody,
    })
      .then(response => response.json())
      .then(data => {
        setStatus(STATE_OK)
        if (data.success) {
          toast(`Thank you, your comment has been submitted`)
        } else {
          throw new Error(data.errorCode)
        }
      })
      .catch(err => {
        setStatus(STATE_ERROR)
        console.error(err)
        toast.error("There was an error processing your message")
      })
  }

  return (
    <form onSubmit={onSubmit} className={style.form}>
      <input name="options[slug]" type="hidden" value={slug} />
      <input name="age" type="hidden" value={honeyPotValue} />
      <p>
        Feel free to leave a comment. Your email will not be published. To avoid
        spam, messages will be reviewed before being published.
      </p>
      <p>
        {status === STATE_OK ? (
          <FormThankYou />
        ) : status === STATE_ERROR ? (
          <FormError />
        ) : (
          false
        )}
      </p>
      <div className={style.field}>
        <textarea name="message" placeholder="Your comment"></textarea>
      </div>
      <div className={style.field}>
        <input name="name" type="text" placeholder="Your name" />
      </div>
      <div className={style.field}>
        <input type="email" name="email" placeholder="Your email address" />
      </div>
      <button type="submit" className="btn" disabled={status === STATE_LOADING}>
        {status === STATE_LOADING ? "...submitting..." : "submit comment"}
      </button>
    </form>
  )
}

export default CommentForm
