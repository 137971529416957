import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import useSiteMetadata from "../static_queries/useSiteMetadata"
import socialShareStyles from "../styles/components/socialshare.module.scss"

const iconSize=32
const buttonBackground = `#b1bcbc`

const SocialShare = ({ title:article_title, author }) => {
  const { title:site_title, url, twitterHandle } = useSiteMetadata()
  const title = article_title + ' | ' + site_title
  return (
  <div className="post-social">
    <FacebookShareButton url={url} className={`${socialShareStyles.button}`} >
      <FacebookIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>Facebook</span>
    </FacebookShareButton>
    <TwitterShareButton url={url} className={`${socialShareStyles.button}`} title={title} via={twitterHandle.split('@').join('')} hashtags={[]} >
      <TwitterIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>Twitter</span>
    </TwitterShareButton>
    <LinkedinShareButton url={url} className={`${socialShareStyles.button}`} title={title} >
      <LinkedinIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>LinkedIn</span>
    </LinkedinShareButton>
    <RedditShareButton url={url} className={`${socialShareStyles.button}`} title={title} >
      <RedditIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>Reddit</span>
    </RedditShareButton>
    <WhatsappShareButton url={url} className={`${socialShareStyles.button}`} title={title} >
      <WhatsappIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>WhatsApp</span>
    </WhatsappShareButton>
    <TumblrShareButton className={`${socialShareStyles.button}`} title={title} tags={[]} caption={`An article by ${author}`}>
      <TumblrIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>Tumblr</span>
    </TumblrShareButton>
    <EmailShareButton className={`${socialShareStyles.button}`} subject={title} body={`Please read this article by ${author}: `}>
      <EmailIcon size={iconSize} bgStyle={{ fill: buttonBackground}} iconFillColor="white"/>
      <span className={socialShareStyles.button__text}>Tumblr</span>
    </EmailShareButton>
  </div>
)};

export default SocialShare