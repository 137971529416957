import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import useBlogData from "../static_queries/useBlogData"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import blogTemplateStyles from "../styles/templates/blog.module.scss"
import SocialShare from "../components/SocialShare"
import CommentForm from "../components/CommentForm"
import Comment from "../components/Comment"
import SEO from "../components/SEO"
import Img from "gatsby-image"

export default function Blog(props) {
  const data = props.data.markdownRemark
  const comments =
    (props.data.allCommentsYaml && props.data.allCommentsYaml.edges) || []

  const allBlogData = useBlogData()
  const { url } = useSiteMetadata()
  const nextSlug = getNextSlug(data.fields.slug)

  function getNextSlug(slug) {
    const allSlugs = allBlogData.map(blog => {
      return blog.node.fields.slug
    })
    const nextSlug = allSlugs[allSlugs.indexOf(slug) + 1]
    if (nextSlug !== undefined && nextSlug !== "") {
      return nextSlug
    } else {
      return allSlugs[0]
    }
  }

  return (
    <Layout>
      <SEO description={data.excerpt} image={data.frontmatter.hero_image.childImageSharp.fixed.src} title={data.frontmatter.title}/>
      <article className={blogTemplateStyles.blog}>
        <figure className={blogTemplateStyles.blog__hero}>
          <Img
            fluid={data.frontmatter.hero_image.childImageSharp.fluid}
            alt={data.frontmatter.title}
          />
        </figure>
        <div className={blogTemplateStyles.blog__info}>
          <h1>{data.frontmatter.title}</h1>
          <h3>{data.frontmatter.date}</h3>
        </div>
        <div
          className={blogTemplateStyles.blog__body}
          dangerouslySetInnerHTML={{ __html: data.html }}
        ></div>
        <div className={blogTemplateStyles.blog__footer}>
          <h2>Written by {data.frontmatter.author}</h2>
          <Link
            to={`/blog/${nextSlug}`}
            className={blogTemplateStyles.footer__next}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 26 26"
              enableBackground="new 0 0 26 26"
            >
              <path d="M23.021,12.294l-8.714-8.715l-1.414,1.414l7.007,7.008H2.687v2h17.213l-7.007,7.006l1.414,1.414l8.714-8.713  C23.411,13.317,23.411,12.685,23.021,12.294z" />
            </svg>
          </Link>
        </div>
        <div
          className={blogTemplateStyles.blog__footer}
          style={{ justifyContent: "flex-end" }}
        >
          <SocialShare
            title={data.frontmatter.title}
            author={data.frontmatter.author}
          />
        </div>
        <div className={blogTemplateStyles.blog__body}>
          <h1 style={{paddingLeft:0}}>
            { comments && comments.length ?
              comments.length > 1
              ? `${comments.length} comments`
              : `1 comment`
              : 'No comments yet'
            }
          </h1>
          {comments &&
            comments.map(({ node: { id, ...rest } }) => (
              <Comment {...rest} key={id}/>
            ))}
          <hr/>
          <CommentForm url={url} slug={data.fields.slug} />
          <hr/>
        </div>
      </article>
    </Layout>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      excerpt(pruneLength: 120)
      frontmatter {
        title
        author
        date(formatString: "MMMM Do, YYYY")
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed{
              src
            }
          }
        }
      }
      html
    }
    allCommentsYaml(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          name
          email
          message
          date
        }
      }
    }
  }
`
