import React from "react"
import format from "date-fns/format"
import style from "./Comment.module.scss"

const Comment = ({ date, email, message, name }) => (
  <div className={style.comment}>
    <p className={style.author}>
      <span className={style.name}>{name}</span>
      <span className={style.date}>
        {format(new Date(date * 1000), "dd/MM/yyyy")}
      </span>
    </p>
    <p className={style.message}>{message}</p>
  </div>
)

export default Comment
